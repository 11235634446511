import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {getAccessToken} from "@/utils/token";

Vue.config.productionTip = false
//导入reset.less
import "./assets/css/reset.less"


router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //判断是否需要登录
    if (to.meta.isLogin) {
        // 查看是否有token
        let accessToken = getAccessToken();
        if (!accessToken) {
            localStorage.setItem("to", to.path)
            next('/login')
        }
    }
    next()
})

import {
    Button,
    Stepper,
    NoticeBar,
    Popup,
    Field,
    Collapse,
    CollapseItem,
    Icon,
    Toast,
    Switch,
    Loading,
    Overlay,
    Badge
} from "vant";//导入
Vue
    .use(Button)
    .use(Stepper)
    .use(NoticeBar)
    .use(Popup)
    .use(Field)
    .use(Collapse)
    .use(CollapseItem)
    .use(Icon)
    .use(Toast)
    .use(Switch)
    .use(Loading)
    .use(Overlay)
    .use(Badge)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
