import axios from "axios";
import {getAccessToken} from "./token";
import router from "../router";
import {Toast} from "vant";

let request = axios.create();

request.defaults.baseURL = process.env.VUE_APP_BASE_URL;
console.log(process.env.VUE_APP_BASE_URL)

// 请求拦截器
request.interceptors.request.use((config) => {
    let token = getAccessToken()
    if (token) {
        config.headers.Authorization = 'Bearer ' + getAccessToken()
    }
    return config
}, (error) =>{
    Promise.reject(error)
})


let messageList = []
// 响应拦截器
request.interceptors.response.use(
    response => {
        if (response.data.code !== 200) {
            Toast(response.data.message)
        }
        return response.data
    },
    error => {
        if (error.response && error.response.status === 403){
            router.push('/login')
        }
        if (error.response && error.response.hasOwnProperty('data')){
            let message = error.response.data.message
            if (error.response.status === 403){
                message = '授权已过期'
            }
            if (!messageList.includes(message)){
                messageList.push(message)
                setTimeout(() =>{
                    messageList = []
                },1000)
            }
        } else {
        }
    }
)

export default request;