<template>
  <div>
    <div class="banner">
<!--      <img @click="toWelcome"  src="../../assets/imgs/logo.png" alt="">-->
        <div @click="toWelcome" class="title">首頁</div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    toWelcome(){
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  height: 60px;
  width: 100%;
  background-color: var(--main-color);
  //border-radius: 0 0 15px 15px;
  //box-shadow: 0 3px 5px #f8a0a0;
  position: fixed;
  top: 0;
  z-index: 1800;
}

.title{
  cursor: pointer;
  margin-left: 20px;
  font-size: 1.5rem;
  color: #fff;
  line-height: 60px;
}

img {
  cursor: pointer;
  width: 110px;
  transform: translate(-18px,-30px);
  background-color: var(--main-color) !important;
}

</style>