import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Home from "../views/home/Home.vue"

const routes = [
    {
        path: '/',
        meta: {title:'首页'},
        component: () => import("../views/welcome/Welcome.vue")
    },
    //首页
    {
        path: "/home",
        meta: {title:'首页'},
        component: Home,
    },
    //登录
    {
        path: "/login",
        meta: {title:'登录'},
        component: () => import("../views/login/Login.vue")
    },
    //注册
    {
        path: "/register",
        meta: {title:'注册'},
        component: () => import("../views/register/Register.vue")
    },
    //重置密码
    {
        path: "/forget/password",
        meta: {title:'重置密码'},
        component: () => import("../views/forgetPassword/ForgetPassword.vue")
    },
    //个人中心
    {
        path: "/personal",
        meta: {isLogin: true,title: '个人中心'},
        component: () => import("../views/personal/Personal.vue")
    },
    //支付
    {
        path: "/pay",
        name:'pay',
        meta: {isLogin: true,title: '支付'},
        component: () => import("../views/pay/Pay.vue")
    },
    {
        path: "/pay/success",
        name:'paySuccess',
        meta: {isLogin: true,title: '支付状态'},
        component: () => import("../views/pay/PaySuccess.vue")
    },
    {
        path: "/pay/cancel",
        name:'payCancel',
        meta: {isLogin: true,title: '取消支付'},
        component: () => import("../views/pay/PayCancel.vue")
    },
    {
        path: "/deliveryAddress",
        name:'deliveryAddress',
        meta: {isLogin: true,title: '收货地址'},
        component: () => import("../views/deliveryAddress/DeliveryAddress.vue")
    },
    {
        path: "/address/detail",
        name:'deliveryAddressDetail',
        meta: {isLogin: true,title: '修改收货地址'},
        component: () => import("../views/deliveryAddress/DeliveryAddressDetail.vue")
    },
    {
        path: "/order/list",
        name:'order',
        meta: {isLogin: true,title: '订单列表'},
        component: () => import("../views/order/Order.vue")
    },
    //404
    {
        path: "/err404",
        meta: {title:'404'},
        component: () => import("../views/404/404.vue")
    },
    {
        path: "*",  //*代表所有,上面path一个都没有匹配到,直接使用*
        redirect: "/err404"  //重定向
    }
]

const router = new VueRouter({
    routes
})

export default router
