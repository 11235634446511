export function storeToken(data) {
    sessionStorage.setItem('accessToken', data.accessToken)
    sessionStorage.setItem('refreshToken', data.refreshToken)
}

export function getAccessToken() {
    return sessionStorage.getItem('accessToken')
}

export function getRefreshToken() {
    return sessionStorage.getItem('refreshToken')
}

export function clearToken() {
    sessionStorage.clear();
}