<template>
  <!-- 步进器 -->
    <div class="stepper">
        <div class="stepper-container">
            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"
                 :class="{sBFsX2Z:true, disabled:disabled}" @click="sub">
                <path fill-rule="evenodd" d="M20,12 L20,13 L5,13 L5,12 L20,12 Z"></path>
            </svg>
            <input type="number" min="1" :value="value" ref="steInput" @input="inputChange"
                   @blur="() => {if(!value) {value=1;Math.floor(value)}}">
            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24" class="sBFsX2Z"
                 @click="add">
                <path fill-rule="evenodd"
                      d="M13,5 L13,12 L20,12 L20,13 L13,13 L13,20 L12,20 L11.999,13 L5,13 L5,12 L12,12 L12,5 L13,5 Z"></path>
            </svg>
        </div>
        <div class="add-car-btn" @click="inCar">
            新增到購物車中
        </div>
    </div>
  <!-- 步进器 -->
</template>

<script>
export default {
    name: "Stepper",
    props: {
        modelValue: {
            type: Number,
            default: 1
        }
    },
    data: function () {
        return {
            value: this.modelValue,
            disabled: true
        }
    },
    methods: {
        add() {
            this.value++
        },
        sub() {
            if (this.value > 1) {
                this.value--
            }
        },
        inCar() {
            this.$emit('addCar', this.value)
            this.value = 1;
        },
        inputChange(e) {
            // 如果不是数字就替换为1
            if (isNaN(e.target.value)) {
                e.target.value = 1;
            }
            this.value = e.target.value;
        }
    },
    watch: {
        value: function (val) {
            // 取整
            this.value = Math.floor(val);
            this.disabled = val <= 1;
        }
    }
}
</script>

<style lang="less" scoped>

.stepper {
  padding: 0 40px;

  .stepper-container {
    border: 2px solid #504e4b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px;

    input {
      flex: 1;
      text-align: center;
      border: none;
      outline: none;
      font-size: 1.2rem;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .van-icon {
      cursor: pointer;
    }

    .sBFsX2Z {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    .sBFsX2Z.disabled {
      fill: #cfcdc9;
      cursor: not-allowed;
    }
  }

  .add-car-btn {
    margin-top: 15px;
    background-color: #4e4a42;
    color: #fff;
    font-size: 1.1rem;
    padding: 15px 30px;
    user-select: none;
    cursor: pointer;
    transition: background-color .3s;
  }

  .add-car-btn:hover {
    background-color: #83807a;
  }
}

</style>