import request from "../utils/request";

export function createOrder(data){
   return request({
       url:'/order/create',
       method:'post',
       data
   })
}

export function getOrderInfo(params){
    return request({
        url:'/order/info',
        method:'get',
        params
    })
}

export function getOrderProductByOrderNo(params){
    return request({
        url:'/order/product',
        method:'get',
        params
    })
}
export function updateOrderAddress(data){
    return request({
        url:'/order/update/address',
        method:'post',
        data
    })
}

export function orderList(){
    return request({
        url:'/order/list',
        method:'get',
    })
}


