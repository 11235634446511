<template>
    <div class="home">
        <div class="occupy-space"></div>
        <van-notice-bar class="notice" :text="noticeContent" background="#cdcdcb" color="#fff" left-icon="volume-o"
                        scrollable delay="0.5"/>
        <div class="goods">
            <div class="item" v-for="(product,index) in productList" :key="index">
                <img :src="product.mainPic" alt="" width="250px" height="250px" style="object-fit: cover;">
                <p>{{ product.productName }}</p>
                <p class="price">£{{ product.price }}</p>
                <Stepper v-model="product.num"
                         @addCar="(val)=> {if(product.num){product.num+=val}else{product.num=val;}changeCar();}"/>
            </div>
        </div>
        <div class="footer" v-show="close">
            <div class="top-warp">
                <div class="personal-btn" @click="toPersonal">
                    <img src="../../assets/imgs/个人中心.png" alt="">
                </div>
                <van-badge :content="totalCount" :max="99">
                    <button @click="show = !show;close = false;" class="btn">購物車</button>
                </van-badge>
            </div>
        </div>
        <!-- 弹出层 -->
        <van-popup v-model="show" @closed="closePopup" duration="0.2"
                   :overlay-style="{backgroundColor: 'rgba(0,0,0,0)',  position: 'absolute'}"
                   round position="bottom"
                   :style="{ height: '50%',backgroundColor:'rgba(0, 0, 0, 0.85)',padding:'10px 10px' }">
            <div class="top-warp popup">
                <div class="personal-btn" @click="toPersonal">
                    <img src="../../assets/imgs/个人中心.png" alt="">
                </div>
                <van-badge :content="totalCount" :max="99">
                    <button @click="show = !show;" class="btn">購物車</button>
                </van-badge>
            </div>
            <div class="pro-list-warp">
                <div v-for="(car,index) in shoppingCarList" :key="index" class="pro">
                    <div class="name">{{ car.productName }}</div>
                    <!--          <div class="num">-->
                    <!--            <van-icon name="cross"/>-->
                    <!--            {{ car.num }}-->
                    <!--          </div>-->
                    <van-stepper class="car-stepper" v-model="car.num" :min="0" default-value="0" theme="round"
                                 @change="changeCar"/>
                </div>
            </div>
            <div class="fee">
                <div class="freight">邮费：£{{ freight }}</div>
                <div class="total">總計：£{{ totalPrice }}</div>
                <div class="pay" @click="toPay">付款</div>
            </div>
            <button class="btn pay" @click="toPay">付款
                <van-icon name="arrow"/>
            </button>
        </van-popup>
    </div>
</template>

<script>
import Header from "@/components/common/Header";
import {list} from "@/api/product";
import {Toast} from 'vant';
import {createOrder} from "@/api/order";
import Stepper from "@/components/common/Stepper.vue";
import {getNoticeContent} from "@/api/notice";

export default {
    components: {Header, Stepper},
    data() {
        return {
            value: 1,
            show: false,
            productList: [],
            close: true,
            shoppingCarList: [],
            disabled: false,
            noticeContent: '',
        }
    },
    created() {
        list().then(res => {
            let {data} = res
            this.productList = data;
        })
        getNoticeContent().then(res => {
            this.noticeContent = res.data
        })
    },
    computed: {
        totalCount() {
            return this.shoppingCarList.map(car => car.num).reduce((pre, cur) => pre + cur, 0)
        },
        freight() {
            return this.totalCount >= 20 ? 0 : 4.99
        },
        totalPrice() {
            // 计算总价并保留两位小数
            return (this.shoppingCarList.map(car => car.num * car.price).reduce((pre, cur) => pre + cur, 0) + this.freight).toFixed(2)
        }
    },
    methods: {
        closePopup() {
            this.close = true
        },
        toPersonal() {
            this.$router.push('/personal')
        },
        changeCar() {
            this.shoppingCarList = this.productList.filter(product => product.num >= 1)
            localStorage.setItem("carList", JSON.stringify(this.shoppingCarList))
        },
        toPay() {
            if (this.shoppingCarList.length < 1) {
                Toast('请添加要购买的商品');
                return
            }
            //创建订单
            let orderList = [];
            this.shoppingCarList.forEach(car => {
                let product = {}
                product.productId = car.id;
                product.num = car.num;
                orderList.push(product)
            })
            createOrder({orderProductVoList: orderList}).then(res => {
                if (res.code !== 200) {
                    Toast(res.message)
                    return
                }
                this.$router.push(
                    {
                        name: 'pay',
                        params: {
                            orderNo: res.data
                        }
                    }
                )
            })
        },
    }
}
</script>

<style lang="less" scoped>
:root:root {
  --van-stepper-input-font-size: 20px;
}

/deep/ .van-stepper--round .van-stepper__plus {
  color: #4e4a42;
  background-color: #fff;
}

/deep/ .van-stepper--round .van-stepper__minus {
  color: #4e4a42;
  border: none;
}

/deep/ .van-notice-bar__content {
  font-size: 16px;
}

/deep/ .van-stepper__input {
  font-size: 18px;
}

/deep/ .car-stepper .van-stepper__input {
  color: #fff;
}

.goods {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  @media screen and (min-width: 1200px) {
    & {
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      flex-wrap: wrap;
    }
  }
}

.pay {
  position: fixed;
  right: 20px;
  bottom: 10px;
}

.price {
  margin: 10px 0;
  font-family: serif;
}

.item {
  width: 400px;
  text-align: center;
  margin-top: 20px;
}

.home {
  position: relative;
  height: 100%;
  padding-top: 150px;
}

.footer {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 65px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 0 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.top-warp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  &.popup {
    position: sticky;
    top: 0;
  }

  .personal-btn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #dc3837;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    img {
      width: 25px;
    }
  }
}

.btn {
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
  background-color: #db3836;
  padding: 5px 15px;
  border-radius: 15px;
  color: #fff;
  font-size: 18px;
}

.notice {
  position: fixed;
  top: 60px;
  //height: 50px;
  width: 100%;
  z-index: 1000;
}

.occupy-space {
  height: 80px;
  background-color: #cdcdcb;
  position: fixed;
  width: 100%;
  top: 0;
}

.icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.pro-list-warp {
  color: #fff;
  font-size: 23px;
  padding: 30px;

  .pro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
  }
}

.fee {
  display: flex;
  position: fixed;
  bottom: 20px;
  color: #fff;
  font-size: .9rem;

  .total {
    font-family: serif;
  }

  .freight {
    font-family: serif;
    margin-right: 20px;
  }
}
</style>